var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import components from './components';
// @ts-ignore
import { vueI18n } from './i18n';
import { createAndMountApp } from './utils/vue-utils';
import '@lawsafrica/law-widgets/dist/components/la-akoma-ntoso';
import '@lawsafrica/law-widgets/dist/components/la-gutter';
import '@lawsafrica/law-widgets/dist/components/la-gutter-item';
import '@lawsafrica/law-widgets/dist/components/la-table-of-contents-controller';
import '@lawsafrica/law-widgets/dist/components/la-decorate-external-refs';
import '@lawsafrica/law-widgets/dist/components/la-decorate-internal-refs';
import '@lawsafrica/law-widgets/dist/components/la-decorate-terms';
// @ts-ignore
import htmx from 'htmx.org';
import { csrfToken } from './api';
var PeachJam = /** @class */ (function () {
    function PeachJam() {
        this.config = {
            appName: 'Peach Jam',
            pdfWorker: '/static/js/pdf.worker-prod.js',
            userHelpLink: '',
            sentry: {
                dsn: null,
                environment: null
            }
        };
        this.components = [];
    }
    PeachJam.prototype.setup = function () {
        window.dispatchEvent(new Event('peachjam.before-setup'));
        this.setupConfig();
        // add the current user agent to the root HTML element for use with pocketlaw
        document.documentElement.setAttribute('data-user-agent', navigator.userAgent.toLowerCase());
        this.setupHtmx();
        this.setupSentry();
        this.createComponents(document.body);
        this.createVueComponents(document.body);
        this.setupTooltips();
        this.setupPopovers();
        this.scrollNavTabs();
        this.clearGACookies();
        window.dispatchEvent(new Event('peachjam.after-setup'));
    };
    PeachJam.prototype.setupConfig = function () {
        var _a;
        var data = (_a = document.getElementById('peachjam-config')) === null || _a === void 0 ? void 0 : _a.innerText;
        if (data) {
            this.config = JSON.parse(data);
        }
    };
    PeachJam.prototype.setupHtmx = function () {
        var _this = this;
        window.htmx = htmx;
        // htmx:load is fired both when the page loads (weird) and when new content is loaded. We only care about the latter
        // case. See https://github.com/bigskysoftware/htmx/issues/1500
        var htmxHelper = { firstLoad: true };
        var token = '';
        document.body.addEventListener('htmx:load', function (e) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (htmxHelper.firstLoad) {
                    htmxHelper.firstLoad = false;
                    return [2 /*return*/];
                }
                // mount components on new elements
                this.createComponents(e.target);
                this.createVueComponents(e.target);
                return [2 /*return*/];
            });
        }); });
        htmx.on('htmx:confirm', function (e) {
            if (e.detail.verb === 'post') {
                e.preventDefault();
                csrfToken().then(function (t) {
                    token = t;
                    e.detail.issueRequest();
                });
            }
        });
        htmx.on('htmx:configRequest', function (e) {
            if (e.detail.verb === 'post') {
                e.detail.headers['X-CSRFToken'] = token;
            }
        });
    };
    PeachJam.prototype.createComponents = function (root) {
        if (root.getAttribute('data-component')) {
            this.createComponent(root);
        }
        // @ts-ignore
        for (var _i = 0, _a = root.querySelectorAll('[data-component]'); _i < _a.length; _i++) {
            var element = _a[_i];
            this.createComponent(element);
        }
        window.dispatchEvent(new Event('peachjam.components-created'));
    };
    PeachJam.prototype.createVueComponents = function (root) {
        // create vue-based components
        // @ts-ignore
        for (var _i = 0, _a = root.querySelectorAll('[data-vue-component]'); _i < _a.length; _i++) {
            var element = _a[_i];
            this.createVueComponent(element);
        }
        window.dispatchEvent(new Event('peachjam.vue-components-created'));
    };
    PeachJam.prototype.createComponent = function (el) {
        var name = el.getAttribute('data-component');
        if (name && components[name]) {
            // create the component and attached it to the HTML element
            el.component = new components[name](el);
            this.components.push(el.component);
        }
    };
    PeachJam.prototype.createVueComponent = function (el) {
        // create vue-based components
        var name = el.getAttribute('data-vue-component');
        if (name && components[name]) {
            var vueComp = components[name];
            createAndMountApp({
                component: vueComp,
                // pass in the element's data attributes as props
                props: __assign({}, el.dataset),
                use: [vueI18n],
                mountTarget: el
            });
            el.component = vueComp;
            this.components.push(vueComp);
        }
    };
    PeachJam.prototype.setupSentry = function () {
        // @ts-ignore
        if (this.config.sentry && window.Sentry) {
            // @ts-ignore
            window.Sentry.init({
                dsn: this.config.sentry.dsn,
                environment: this.config.sentry.environment,
                allowUrls: [
                    new RegExp(window.location.host.replace('.', '\\.') + '/static/')
                ],
                denyUrls: [
                    new RegExp(window.location.host.replace('.', '\\.') + '/static/lib/pdfjs/')
                ],
                beforeSend: function (event) {
                    try {
                        // if there is no stacktrace, ignore it
                        if (!event.exception || !event.exception.values || !event.exception.values[0] || !event.exception.values[0].stacktrace) {
                            return null;
                        }
                        var frames_1 = event.exception.values[0].stacktrace.frames;
                        // if first frame is anonymous, don't send this event
                        // see https://github.com/getsentry/sentry-javascript/issues/3147
                        if (frames_1 && frames_1.length > 0) {
                            var firstFrame = frames_1[0];
                            if (!firstFrame.filename || firstFrame.filename === '<anonymous>') {
                                return null;
                            }
                        }
                    }
                    catch (e) {
                        // ignore error, send event
                        console.log(e);
                    }
                    return event;
                }
            });
        }
    };
    PeachJam.prototype.setupTooltips = function () {
        // setup bootstrap tooltips
        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(function (el) {
            // @ts-ignore
            new window.bootstrap.Tooltip(el);
        });
    };
    PeachJam.prototype.setupPopovers = function () {
        document.querySelectorAll('[data-bs-toggle="help-popover"]').forEach(function (el) {
            var _a;
            // @ts-ignore
            var helpPopover = new window.bootstrap.Popover(el, {
                html: true,
                content: "\n        ".concat(el.getAttribute('data-bs-content'), "\n         <div><a href=\"").concat(((_a = el.getAttribute('href')) === null || _a === void 0 ? void 0 : _a.split('#')[1]) || '#', "\" target='_blank' rel='noopener noreferrer'>Learn more</a></div>\n        "),
                container: 'body'
            });
            // keep popover open when clicking inside it but close when clicking outside it
            el.addEventListener('inserted.bs.popover', function (e) {
                var popoverBody = document.querySelector('.popover-body');
                var clickListener = function (e) {
                    // Close the popover if the click is outside the popover
                    if (!(popoverBody === null || popoverBody === void 0 ? void 0 : popoverBody.contains(e.target))) {
                        helpPopover.hide();
                        document.removeEventListener('click', clickListener);
                    }
                };
                document.addEventListener('click', clickListener);
            });
        });
    };
    PeachJam.prototype.scrollNavTabs = function () {
        // for scrollable tabbed navs, the active element may be out of view on narrow devices; scroll it into view
        document.querySelectorAll('.nav.nav-tabs.scroll-xs > .nav-item > .nav-link.active').forEach(function (link) {
            if (link.parentElement && link.parentElement.parentElement) {
                link.parentElement.parentElement.scrollLeft = link.offsetLeft;
            }
        });
    };
    PeachJam.prototype.clearGACookies = function () {
        // if window.dataLayer is not set, then Google Analytics is not enabled, but there may be cookies still set; clear them
        // @ts-ignore
        if (!window.dataLayer) {
            var cookies = [];
            try {
                cookies = document.cookie.split(';');
            }
            catch (_a) {
                // ignore security errors when reading cookies
                return;
            }
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var eqPos = cookie.indexOf('=');
                var name_1 = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
                if (name_1.trim().startsWith('_ga')) {
                    document.cookie = "".concat(name_1, "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.").concat(window.location.hostname);
                }
            }
        }
    };
    return PeachJam;
}());
var peachJam = new PeachJam();
export default peachJam;
