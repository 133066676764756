import peachJam from '../../peachjam';
import i18next from 'i18next';
/**
 * Adds a popup to search the site based on selected document text.
 */
var SelectionSearch = /** @class */ (function () {
    function SelectionSearch(manager) {
        this.manager = manager;
        this.manager.addProvider(this);
    }
    SelectionSearch.prototype.getButton = function (target) {
        var btn = document.createElement('button');
        btn.className = 'btn btn-outline-secondary';
        btn.type = 'button';
        btn.innerText = i18next.t('Search {{appName}}...', { appName: peachJam.config.appName });
        return btn;
    };
    SelectionSearch.prototype.addEnrichment = function (target) {
        if (target.selectors) {
            // get the selected text
            var quoteSelector = target.selectors.find(function (x) { return x.type === 'TextQuoteSelector'; });
            if (quoteSelector && quoteSelector.exact) {
                document.location = '/search/?q=' + encodeURIComponent(quoteSelector.exact);
            }
        }
    };
    return SelectionSearch;
}());
export default SelectionSearch;
