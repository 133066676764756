import ShareSelectionModal from './ShareSelectionModal.vue';
import i18next from 'i18next';
import { createAndMountApp } from '../../utils/vue-utils';
import { vueI18n } from '../../i18n';
/**
 * Adds a popup to share the selection provision or text.
 */
var SelectionShare = /** @class */ (function () {
    function SelectionShare(manager) {
        var _a, _b;
        this.manager = manager;
        this.manager.addProvider(this);
        // @ts-ignore
        this.documentTitle = ((_b = (_a = document.querySelector('.document-content')) === null || _a === void 0 ? void 0 : _a.dataset) === null || _b === void 0 ? void 0 : _b.title) || '';
    }
    SelectionShare.prototype.getButton = function (target) {
        var btn = document.createElement('button');
        btn.className = 'btn btn-outline-secondary';
        btn.type = 'button';
        btn.innerText = i18next.t('Share...');
        return btn;
    };
    SelectionShare.prototype.addEnrichment = function (target) {
        if (target.selectors) {
            // get the selected text
            var quoteSelector = target.selectors.find(function (x) { return x.type === 'TextQuoteSelector'; });
            if (quoteSelector && quoteSelector.exact) {
                var url = new URL(window.location.toString());
                url.hash = target.anchor_id;
                // open the share dialog
                createAndMountApp({
                    component: ShareSelectionModal,
                    props: {
                        url: url.toString(),
                        text: "".concat(quoteSelector.exact, " - ").concat(this.documentTitle)
                    },
                    use: [vueI18n],
                    mountTarget: document.createElement('div')
                });
            }
        }
    };
    return SelectionShare;
}());
export default SelectionShare;
