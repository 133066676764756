import { RelationshipEnrichments } from '../RelationshipEnrichment';
import DocDiffsManager from '../DocDiffs';
import PDFCitationLinks from './citation-links';
import { GutterEnrichmentManager } from '@lawsafrica/indigo-akn/dist/enrichments';
import SelectionSearch from './selection-search';
import SelectionShare from './selection-share';
/**
 * Class for handling the setup of all enrichments and interactions between enrichments
 */
var EnrichmentsManager = /** @class */ (function () {
    function EnrichmentsManager(contentAndEnrichmentsElement) {
        var _this = this;
        var _a;
        this.relationshipsManager = null;
        this.citationLinks = null;
        this.root = contentAndEnrichmentsElement;
        this.gutter = this.root.querySelector('la-gutter');
        // this is either div.content (for HTML and PDF) or la-akoma-ntoso.content (for AKN)
        this.akn = this.root.querySelector('.content');
        this.displayType = this.root.getAttribute('data-display-type') || 'html';
        this.docDiffsManager = this.setupDocDiffs();
        this.gutterManager = new GutterEnrichmentManager(this.root);
        // @ts-ignore
        // GutterEnrichmentManager by default looks for la-akoma-ntoso, and we might not be working with that
        this.gutterManager.akn = this.root.querySelector('.content');
        if (this.displayType !== 'pdf') {
            this.relationshipsManager = new RelationshipEnrichments(this.root, this.gutterManager, this.displayType);
        }
        this.selectionSearch = new SelectionSearch(this.gutterManager);
        this.selectionShare = new SelectionShare(this.gutterManager);
        (_a = this.gutter) === null || _a === void 0 ? void 0 : _a.addEventListener('laItemChanged', function (e) {
            var _a;
            if (e.target.classList.contains('relationship-gutter-item') && e.target.active) {
                (_a = _this.docDiffsManager) === null || _a === void 0 ? void 0 : _a.closeInlineDiff();
            }
        });
    }
    EnrichmentsManager.prototype.setupDocDiffs = function () {
        if (!this.akn || !this.gutter)
            return null;
        var frbrExpressionUri = this.akn.getAttribute('frbr-expression-uri');
        if (!frbrExpressionUri)
            return null;
        return new DocDiffsManager(frbrExpressionUri, this.gutter, this.root.getAttribute('data-diffs-url') || '');
    };
    EnrichmentsManager.prototype.addPdfEnrichments = function () {
        // setup PDF enrichments after the PDF has been rendered
        this.relationshipsManager = new RelationshipEnrichments(this.root, this.gutterManager, this.displayType);
        this.citationLinks = new PDFCitationLinks(this.root, this.gutterManager);
    };
    return EnrichmentsManager;
}());
export default EnrichmentsManager;
