import { createI18n } from 'vue-i18n';
import i18n from 'i18next';
var languageSelect = document.getElementById('language');
var langs = languageSelect ? Array.from(languageSelect.querySelectorAll('option'))
    .map(function (option) { return option.value; }) : ['en'];
var selectedLang = languageSelect ? languageSelect.options[languageSelect.selectedIndex].value : 'en';
var loadJSONFile = function (url) {
    if (url === void 0) { url = ''; }
    try {
        return require("./locale/".concat(url));
    }
    catch (e) {
        return null;
    }
};
var setUpI8n = function () {
    var resources = {};
    langs.forEach(function (key) {
        resources[key] = {
            translation: loadJSONFile("".concat(key, "/translation.json"))
        };
    });
    i18n.init({
        fallbackLng: 'en',
        lng: selectedLang,
        resources: resources
    });
};
// Setup Vue i18next for vue files
var setupVueI8n = function () {
    var messages = {};
    langs.forEach(function (key) {
        messages[key] = loadJSONFile("".concat(key, "/translation.json"));
    });
    var vueOptions = {
        fallbackLocale: 'en',
        locale: selectedLang,
        messages: messages
    };
    return createI18n(vueOptions);
};
// Setup i8next for js files
setUpI8n();
// Setup Vue i18next for vue files
export var vueI18n = setupVueI8n();
