import { createApp, defineComponent } from 'vue';
export var createAndMountApp = function (_a) {
    var component = _a.component, _b = _a.props, props = _b === void 0 ? {} : _b, _c = _a.use, use = _c === void 0 ? [] : _c, mountTarget = _a.mountTarget;
    var definedComponent = defineComponent(component);
    var app = createApp(definedComponent, props);
    use === null || use === void 0 ? void 0 : use.forEach(function (plugin) {
        app.use(plugin);
    });
    return app.mount(mountTarget);
};
