var ToggleTab = /** @class */ (function () {
    function ToggleTab(anchor) {
        var _this = this;
        this.anchor = anchor;
        this.anchor.addEventListener('click', function (e) { return _this.clicked(e); });
    }
    ToggleTab.prototype.clicked = function (e) {
        e.preventDefault();
        var href = this.anchor.getAttribute('href');
        if (href) {
            var trigger = document.querySelector("[data-bs-target=\"".concat(href, "\"]"));
            if (trigger) {
                // @ts-ignore
                var tab = new window.bootstrap.Tab(trigger);
                tab.show();
            }
        }
    };
    return ToggleTab;
}());
export { ToggleTab };
