var DocumentTable = /** @class */ (function () {
    function DocumentTable(root) {
        this.root = root;
        this.setupSorting();
    }
    DocumentTable.prototype.setupSorting = function () {
        var _this = this;
        this.root.querySelectorAll('[data-sort]').forEach(function (el) {
            el.addEventListener('click', function (e) {
                _this.setSort(el.getAttribute('data-sort') || '');
            });
        });
    };
    DocumentTable.prototype.setSort = function (value) {
        // find the enclosing form element and set the value of the name=sort input
        var form = this.root.closest('form');
        if (form && form.sort) {
            form.sort.value = value;
            form.sort.dispatchEvent(new Event('change'));
        }
    };
    return DocumentTable;
}());
export default DocumentTable;
