import { generateHtmlTocItems } from '../utils/function';
var TermsOfUse = /** @class */ (function () {
    function TermsOfUse(root) {
        var content = root.querySelector('.terms-of-use-content');
        var tocContainers = Array.from(root.querySelectorAll('.navigation-toc'));
        if (!content || !tocContainers.length)
            return;
        var items = generateHtmlTocItems(content);
        var nav = document.getElementById('side-nav');
        var offCanvas = new window.bootstrap.Offcanvas(nav);
        tocContainers.forEach(function (element) {
            var tableOfContents = document.createElement('la-table-of-contents');
            tableOfContents.items = items;
            element.appendChild(tableOfContents);
            tableOfContents.addEventListener('itemTitleClicked', function (e) {
                offCanvas === null || offCanvas === void 0 ? void 0 : offCanvas.hide();
            });
        });
    }
    return TermsOfUse;
}());
export default TermsOfUse;
