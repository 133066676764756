var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export function reverseRelationship(relationship) {
    for (var _i = 0, _a = ['work', 'target_id', 'documents']; _i < _a.length; _i++) {
        var attr = _a[_i];
        // @ts-ignore
        var val = relationship["subject_".concat(attr)];
        // @ts-ignore
        relationship["subject_".concat(attr)] = relationship["object_".concat(attr)];
        // @ts-ignore
        relationship["object_".concat(attr)] = val;
    }
}
export function bestDocument(docs, language) {
    if (!docs.length) {
        return null;
    }
    // sort docs by date, descending order
    var sorted = __spreadArray([], docs, true).sort(function (a, b) { return -a.date.localeCompare(b.date); });
    // find the first doc in our language, or fall back to the first doc
    return sorted.find(function (d) { return d.language3 === language; }) || sorted[0];
}
