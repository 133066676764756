var DocumentFilterForm = /** @class */ (function () {
    function DocumentFilterForm(root) {
        var _this = this;
        this.offCanvasUsed = false;
        this.root = root;
        // setup a resize observer to move the filters when the window is resized
        var observer = new ResizeObserver(function () { return _this.moveFilters(); });
        observer.observe(this.root);
        this.moveFilters();
    }
    DocumentFilterForm.prototype.moveFilters = function () {
        // on small displays, move the filters into the offcanvas element
        if (window.innerWidth < 992) {
            this.moveFiltersToOffcanvas();
        }
        else {
            this.moveFiltersToMainContent();
        }
    };
    DocumentFilterForm.prototype.moveFiltersToOffcanvas = function () {
        var _this = this;
        if (!this.offCanvasUsed) {
            var offcanvas_1 = this.root.querySelector('.offcanvas-body');
            var content_1 = this.root.querySelector('.document-list-facets');
            if (offcanvas_1 && content_1) {
                requestAnimationFrame(function () {
                    offcanvas_1.appendChild(content_1);
                    _this.offCanvasUsed = true;
                });
            }
        }
    };
    DocumentFilterForm.prototype.moveFiltersToMainContent = function () {
        var _this = this;
        if (this.offCanvasUsed) {
            var wrapper_1 = this.root.querySelector('.document-list-facets-wrapper');
            var content_2 = this.root.querySelector('.document-list-facets');
            if (wrapper_1 && content_2) {
                requestAnimationFrame(function () {
                    wrapper_1.appendChild(content_2);
                    _this.offCanvasUsed = false;
                });
            }
        }
    };
    return DocumentFilterForm;
}());
export default DocumentFilterForm;
