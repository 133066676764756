var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { markRange, targetToRange } from '@lawsafrica/indigo-akn/dist/ranges';
import { createAndMountApp } from '../../utils/vue-utils';
import { vueI18n } from '../../i18n';
import CitationLinkModal from './CitationLinkModal.vue';
import CitationLinkGutterItem from './CitationLinkGutterItem.vue';
import { authHeaders } from '../../api';
/**
 * Inserts citation links into rendered PDF documents, and allows admins to manage them.
 */
var PDFCitationLinks = /** @class */ (function () {
    function PDFCitationLinks(root, manager) {
        this.modal = null;
        this.editable = true;
        this.anchors = new Map();
        this.gutterItems = new Map();
        this.root = root;
        this.documentId = root.dataset.documentId || null;
        this.manager = manager;
        var el = document.getElementById('citation-links');
        this.links = JSON.parse((el ? el.textContent : '') || '[]');
        this.editable = this.root.hasAttribute('data-editable-citation-links');
        this.applyLinks();
        if (this.editable) {
            this.modal = this.createModal();
            this.manager.addProvider(this);
        }
    }
    PDFCitationLinks.prototype.applyLinks = function () {
        for (var _i = 0, _a = this.links; _i < _a.length; _i++) {
            var link = _a[_i];
            this.applyLink(link);
        }
    };
    PDFCitationLinks.prototype.applyLink = function (link) {
        var _a;
        var target = {
            anchor_id: link.target_id,
            selectors: link.target_selectors
        };
        var range = targetToRange(target, this.root);
        if (!range)
            return;
        var elements = [];
        markRange(range, 'a', function (a) {
            a.setAttribute('href', link.url);
            elements.push(a);
            return a;
        });
        this.anchors.set(link, elements);
        if (this.editable && elements.length > 0) {
            // add a gutter item
            // @ts-ignore
            (_a = this.manager.gutter) === null || _a === void 0 ? void 0 : _a.appendChild(this.createGutterItem(link, elements[0]));
        }
    };
    PDFCitationLinks.prototype.createModal = function () {
        return createAndMountApp({
            component: CitationLinkModal,
            props: {},
            use: [vueI18n],
            mountTarget: document.createElement('div')
        });
    };
    PDFCitationLinks.prototype.createGutterItem = function (link, anchor) {
        var item = createAndMountApp({
            component: CitationLinkGutterItem,
            props: {
                link: link,
                anchorElement: anchor,
                provider: this
            },
            use: [vueI18n],
            mountTarget: document.createElement('div')
        }).$el;
        this.gutterItems.set(link, item);
        return item;
    };
    PDFCitationLinks.prototype.editLink = function (link) {
        var _this = this;
        if (this.modal) {
            // @ts-ignore
            this.modal.showModal(link).then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                var resp, _a, _b, _c;
                var _d;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            if (!result) return [3 /*break*/, 3];
                            _a = fetch;
                            _b = ["/api/citation-links/".concat(link.id, "/")];
                            _d = {
                                method: 'PUT'
                            };
                            _c = [{}];
                            return [4 /*yield*/, authHeaders()];
                        case 1: return [4 /*yield*/, _a.apply(void 0, _b.concat([(
                                // @ts-ignore
                                _d.headers = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_e.sent())])), { 'Content-Type': 'application/json' }]),
                                    _d.body = JSON.stringify(link),
                                    _d)]))];
                        case 2:
                            resp = _e.sent();
                            if (resp.ok) {
                                this.unapplyLink(link);
                                this.applyLink(link);
                            }
                            return [3 /*break*/, 4];
                        case 3:
                            this.deleteLink(link);
                            _e.label = 4;
                        case 4: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    PDFCitationLinks.prototype.deleteLink = function (link) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, _a, _b;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = fetch;
                        _b = ["/api/citation-links/".concat(link.id, "/")];
                        _c = {
                            method: 'DELETE'
                        };
                        return [4 /*yield*/, authHeaders()];
                    case 1: return [4 /*yield*/, _a.apply(void 0, _b.concat([(
                            // @ts-ignore
                            _c.headers = _d.sent(),
                                _c)]))];
                    case 2:
                        resp = _d.sent();
                        if (resp.ok) {
                            this.unapplyLink(link);
                            this.links.splice(this.links.indexOf(link), 1);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PDFCitationLinks.prototype.unapplyLink = function (link) {
        var _a, _b;
        // remove the anchors
        for (var _i = 0, _c = this.anchors.get(link) || []; _i < _c.length; _i++) {
            var el = _c[_i];
            var span = document.createElement('span');
            span.innerText = el.innerText;
            (_a = el.parentElement) === null || _a === void 0 ? void 0 : _a.insertBefore(span, el);
            el.remove();
        }
        this.anchors.delete(link);
        (_b = this.gutterItems.get(link)) === null || _b === void 0 ? void 0 : _b.remove();
    };
    PDFCitationLinks.prototype.getButton = function (target) {
        var btn = document.createElement('button');
        btn.className = 'btn btn-outline-secondary';
        btn.type = 'button';
        btn.innerText = 'Link citation...';
        return btn;
    };
    PDFCitationLinks.prototype.addEnrichment = function (target) {
        var _this = this;
        // A user has highlighted text and clicked the "Link citation" button
        if (this.modal && target.selectors) {
            // get the selected text
            var quoteSelector = target.selectors.find(function (x) { return x.type === 'TextQuoteSelector'; });
            if (quoteSelector) {
                var link_1 = {
                    id: null,
                    document: this.documentId,
                    text: quoteSelector.exact || '',
                    url: '',
                    target_id: target.anchor_id,
                    target_selectors: target.selectors || []
                };
                // @ts-ignore
                this.modal.showModal(link_1).then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                    var resp, _a, _b, _c, _d;
                    var _e;
                    return __generator(this, function (_f) {
                        switch (_f.label) {
                            case 0:
                                if (!result) return [3 /*break*/, 4];
                                _a = fetch;
                                _b = ['/api/citation-links/'];
                                _e = {
                                    method: 'POST'
                                };
                                _c = [{}];
                                return [4 /*yield*/, authHeaders()];
                            case 1: return [4 /*yield*/, _a.apply(void 0, _b.concat([(
                                    // @ts-ignore
                                    _e.headers = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { 'Content-Type': 'application/json' }]),
                                        _e.body = JSON.stringify(link_1),
                                        _e)]))];
                            case 2:
                                resp = _f.sent();
                                if (!resp.ok) return [3 /*break*/, 4];
                                _d = link_1;
                                return [4 /*yield*/, resp.json()];
                            case 3:
                                _d.id = (_f.sent()).id;
                                this.links.push(link_1);
                                this.applyLink(link_1);
                                _f.label = 4;
                            case 4: return [2 /*return*/];
                        }
                    });
                }); });
            }
        }
    };
    return PDFCitationLinks;
}());
export default PDFCitationLinks;
