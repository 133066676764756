// Reusable utility functions
export function scrollToElement(elem, callback, offset) {
    if (callback === void 0) { callback = function () { return false; }; }
    if (offset === void 0) { offset = 0; }
    if (window.IntersectionObserver === undefined)
        return;
    // Setup intersection observer
    var observer = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                observer.unobserve(entry.target);
                // If intersect element run callback and unobserve
                window.setTimeout(function () {
                    callback(elem);
                }, 500);
            }
        });
    });
    observer.observe(elem);
    var rect = elem.getBoundingClientRect();
    var targetPosition = Math.floor(rect.top + self.pageYOffset - offset);
    window.scrollTo({
        top: targetPosition,
        behavior: 'smooth'
    });
    // if scroll interrupted, cancel observation
    var isScrolling;
    var clearFn = function () {
        // Clear our timeout throughout the scroll
        window.clearTimeout(isScrolling);
        // Set a timeout to run after scrolling ends
        isScrolling = setTimeout(function () {
            // Unobserve element
            observer.unobserve(elem);
            // Remove listener after unobserve
            window.removeEventListener('scroll', clearFn);
        }, 66);
    };
    // Listen for scroll events
    window.addEventListener('scroll', clearFn, false);
}
/**
 * Infer a table of contents (in the form of a list of TOC items) for general HTML, based h* tags.
 */
export function generateHtmlTocItems(content) {
    var stack;
    var items = [];
    var ids = new Map();
    content.querySelectorAll('h1, h2, h3, h4, h5').forEach(function (heading) {
        if (!heading.innerText)
            return;
        if (!heading.id) {
            ids.set(heading.tagName, (ids.get(heading.tagName) || 0) + 1);
            heading.id = heading.tagName + '_' + ids.get(heading.tagName);
        }
        var item = {
            type: heading.tagName,
            title: heading.innerText,
            id: heading.id,
            children: []
        };
        // top level
        if (!stack) {
            items.push(item);
            stack = [item];
        }
        else {
            // find the best sibling for this entry; if the stack is at h3 and we have h2, find an h2 or h1
            while (stack.length && stack[stack.length - 1].type > heading.tagName) {
                stack.pop();
            }
            var top_1 = stack[stack.length - 1];
            if (top_1) {
                if (top_1.type === heading.tagName) {
                    // siblings
                    if (stack.length > 1) {
                        stack[stack.length - 2].children.push(item);
                    }
                    else {
                        items.push(item);
                    }
                    stack[stack.length - 1] = item;
                }
                else {
                    // child
                    top_1.children.push(item);
                    stack.push(item);
                }
            }
        }
    });
    return items;
}
/**
 * Ensure the HTML covered by these TOC items are wrapped in nested divs.
 */
export function wrapTocItems(root, items) {
    var _a;
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        var nextItem = i + 1 < items.length ? items[i + 1] : null;
        var nextId = nextItem ? nextItem.id : null;
        var el = document.getElementById(item.id);
        if (el) {
            var wrapper = document.createElement('div');
            wrapper.id = item.id;
            el.removeAttribute('id');
            (_a = el.parentElement) === null || _a === void 0 ? void 0 : _a.insertBefore(wrapper, el);
            // wrap all content from this heading to the next in a div
            // keep going until we run out of elements, or we hit the next TOC item
            var node = el;
            // eslint-disable-next-line no-unmodified-loop-condition
            while (node && !(node instanceof HTMLElement && nextId != null && node.id === nextId)) {
                var nextEl = node.nextSibling;
                wrapper.appendChild(node);
                node = nextEl;
            }
            if (item.children) {
                wrapTocItems(wrapper, item.children);
            }
        }
    }
}
export var createTocController = function (items) {
    if (items === void 0) { items = []; }
    var laTocController = document.createElement('la-table-of-contents-controller');
    laTocController.items = items;
    laTocController.expandAllBtnClasses = 'btn btn-secondary btn-sm';
    laTocController.collapseAllBtnClasses = 'btn btn-secondary btn-sm';
    laTocController.titleFilterInputClasses = 'form-control';
    laTocController.titleFilterClearBtnClasses = 'btn btn-secondary btn-sm';
    return laTocController;
};
