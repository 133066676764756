import RelationshipEnrichmentList from './RelationshipEnrichmentList.vue';
import { vueI18n } from '../../i18n';
import { createAndMountApp } from '../../utils/vue-utils';
var RelationshipEnrichments = /** @class */ (function () {
    function RelationshipEnrichments(root, manager, displayType) {
        var _this = this;
        this.root = root;
        this.manager = manager;
        this.displayType = displayType;
        this.gutter = root.querySelector('la-gutter');
        this.akn = root.querySelector('la-akoma-ntoso');
        this.workFrbrUri = root.dataset.workFrbrUri || '';
        this.workId = root.dataset.workId || '';
        this.editable = this.root.hasAttribute('data-editable-relationships');
        var node = document.getElementById('provision-relationships');
        if (node) {
            this.enrichments = JSON.parse(node.innerText || '[]');
        }
        else {
            this.enrichments = [];
        }
        // @ts-ignore
        this.listComponent = createAndMountApp({
            component: RelationshipEnrichmentList,
            props: {
                gutter: this.gutter,
                viewRoot: this.root,
                enrichments: this.enrichments,
                editable: this.editable,
                useSelectors: this.displayType === 'pdf',
                thisWorkFrbrUri: this.workFrbrUri
            },
            use: [vueI18n],
            mountTarget: document.createElement('div')
        });
        var observer = new MutationObserver(function () {
            // @ts-ignore
            _this.listComponent.markAndAnchorAll();
        });
        if (this.akn) {
            observer.observe(this.akn, { childList: true });
        }
        if (this.editable) {
            this.manager.addProvider(this);
        }
    }
    RelationshipEnrichments.prototype.getButton = function (target) {
        var btn = document.createElement('button');
        btn.className = 'btn btn-outline-secondary';
        btn.type = 'button';
        btn.innerText = 'Add relationship...';
        return btn;
    };
    RelationshipEnrichments.prototype.addEnrichment = function (target) {
        // @ts-ignore
        this.listComponent.creating = {
            id: null,
            predicate_id: null,
            predicate: {},
            subject_work_id: this.workId,
            subject_work: {
                frbr_uri: this.workFrbrUri
            },
            subject_target_id: target.anchor_id,
            subject_selectors: target.selectors,
            subject_documents: [],
            object_work: {},
            object_target_id: null,
            object_documents: []
        };
    };
    return RelationshipEnrichments;
}());
export { RelationshipEnrichments };
