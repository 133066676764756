var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import debounce from 'lodash/debounce';
import peachJam from '../../peachjam';
import { scrollToElement } from '../../utils/function';
import i18next from 'i18next';
var pdfjsLib = require('pdfjs-dist');
var PdfRenderer = /** @class */ (function () {
    function PdfRenderer(root, manager) {
        var _this = this;
        var _a;
        this.scrollListenerActive = true;
        this.pdfContentMarks = [];
        this.onPreviewPanelClick = function () { };
        this.onPdfLoaded = function () { };
        this.root = root;
        this.manager = manager;
        this.pdfjsLib = pdfjsLib;
        if (!this.pdfjsLib) {
            throw new Error('Failed to load pdf.js');
        }
        this.pdfjsLib.GlobalWorkerOptions.workerSrc = peachJam.config.pdfWorker;
        this.pdfUrl = root.dataset.pdf;
        this.pdfContentWrapper = root.querySelector('.pdf-content');
        this.progressBarElement = root.querySelector('.progress-bar');
        this.previewPanelsContainer = root.querySelector('.pdf-previews');
        (_a = this.root.querySelector('button[data-load-doc-button]')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', function () {
            _this.loadPdf();
        });
        if (!Object.keys(this.root.dataset).includes('largePdf')) {
            this.loadPdf();
        }
    }
    PdfRenderer.prototype.loadPdf = function () {
        var _this = this;
        // should we jump to a specific page?
        var initialPage = null;
        if ((document.location.hash || '').startsWith('#page-')) {
            try {
                initialPage = parseInt(document.location.hash.substring(6));
            }
            catch (_a) { }
        }
        this.root.removeAttribute('data-large-pdf');
        this.setupPdfAndPreviewPanels(initialPage).then(function () {
            _this.setupPreviewSyncing();
            _this.onPdfLoaded();
        }).catch(function (e) {
            throw e;
        });
    };
    PdfRenderer.prototype.setupPreviewSyncing = function () {
        var _this = this;
        var pages = Array.from(this.root.querySelectorAll('.pdf-content__page'));
        window.addEventListener('scroll', debounce(function () {
            if (!_this.scrollListenerActive)
                return;
            var current;
            for (var _i = 0, pages_1 = pages; _i < pages_1.length; _i++) {
                var page = pages_1[_i];
                if (!(window.scrollY >= page.offsetTop))
                    return;
                current = _this.root.querySelector(".preview-panel[data-page=\"".concat(page.dataset.page, "\"]"));
                if (current) {
                    _this.activatePreviewPanel(current);
                    var scrollableContainer = _this.root.querySelector('[data-preview-scroll-container]');
                    if (scrollableContainer) {
                        scrollableContainer.scrollTop = (current.offsetTop + current.clientHeight) - (current.offsetHeight * 2);
                    }
                }
            }
        }, 20));
    };
    PdfRenderer.prototype.activatePreviewPanel = function (nextActivePreviewPanel) {
        for (var _i = 0, _a = Array.from(this.root.querySelectorAll('.preview-panel')); _i < _a.length; _i++) {
            var element = _a[_i];
            element.classList.remove('active');
            if (element === nextActivePreviewPanel) {
                if ('classList' in nextActivePreviewPanel) {
                    nextActivePreviewPanel.classList.add('active');
                }
            }
        }
    };
    PdfRenderer.prototype.handlePreviewPanelClick = function (e) {
        this.onPreviewPanelClick();
        if (!e.currentTarget)
            return;
        this.activatePreviewPanel(e.currentTarget);
        if (!(e.currentTarget instanceof HTMLElement))
            return;
        document.location.hash = "#page-".concat(e.currentTarget.dataset.page);
        // update the current portion for sharing purposes
        var portion = i18next.t('Page') + ' ' + e.currentTarget.dataset.page;
        this.manager.setSharedPortion(portion);
        this.scrollToPage(e.currentTarget.dataset.page);
    };
    PdfRenderer.prototype.scrollToPage = function (pageNumber) {
        var _this = this;
        var targetPage = this.root.querySelector(".pdf-content__page[data-page=\"".concat(pageNumber, "\"]"));
        if (!targetPage)
            return;
        this.scrollListenerActive = false;
        scrollToElement(targetPage, function () {
            _this.scrollListenerActive = true;
        });
    };
    PdfRenderer.prototype.triggerScrollToPage = function (pageNumber) {
        var panel = this.root.querySelector(".preview-panel[data-page='".concat(pageNumber, "']"));
        if (!panel)
            return;
        this.activatePreviewPanel(panel);
        this.scrollToPage(pageNumber);
    };
    PdfRenderer.prototype.setupPdfAndPreviewPanels = function (initialPage) {
        return __awaiter(this, void 0, void 0, function () {
            var docElement, containerWidth, scale, loadingTask, pdf, canvas, i, page, ctx, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        docElement = document.querySelector('.content-and-enrichments .content');
                        if (!docElement)
                            return [2 /*return*/];
                        containerWidth = docElement.clientWidth || 0;
                        scale = 2;
                        this.root.removeAttribute('data-pdf-standby');
                        this.root.setAttribute('data-pdf-loading', '');
                        loadingTask = this.pdfjsLib.getDocument(this.pdfUrl);
                        loadingTask.onProgress = function (data) {
                            if (data.total && _this.progressBarElement) {
                                var progress = data.loaded / data.total * 100;
                                _this.progressBarElement.style.width = "".concat(progress, "%");
                                _this.progressBarElement.innerText = "".concat(Math.ceil(progress), "%");
                            }
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 9]);
                        return [4 /*yield*/, loadingTask.promise];
                    case 2:
                        pdf = _a.sent();
                        this.root.removeAttribute('data-pdf-loading');
                        canvas = document.createElement('canvas');
                        i = 0;
                        _a.label = 3;
                    case 3:
                        if (!(i < pdf.numPages)) return [3 /*break*/, 7];
                        return [4 /*yield*/, pdf.getPage(i + 1)];
                    case 4:
                        page = _a.sent();
                        return [4 /*yield*/, this.renderSinglePage(page, i, scale, containerWidth, canvas)];
                    case 5:
                        _a.sent();
                        if (initialPage && initialPage === i + 1) {
                            this.scrollToPage(i + 1);
                        }
                        _a.label = 6;
                    case 6:
                        i++;
                        return [3 /*break*/, 3];
                    case 7:
                        // free up the canvas so that iOS doesn't complain about memory usage
                        // see https://pqina.nl/blog/total-canvas-memory-use-exceeds-the-maximum-limit/
                        canvas.width = 1;
                        canvas.height = 1;
                        ctx = canvas.getContext('2d');
                        if (ctx)
                            ctx.clearRect(0, 0, 1, 1);
                        return [3 /*break*/, 9];
                    case 8:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    PdfRenderer.prototype.renderSinglePage = function (page, index, scale, containerWidth, canvas) {
        return __awaiter(this, void 0, void 0, function () {
            var viewport, context, renderContext, pageContainer, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        viewport = page.getViewport({ scale: scale });
                        canvas.style.display = 'block';
                        // set the logical size of the canvas to match the scaled-up size of the viewport
                        canvas.width = viewport.width;
                        canvas.height = viewport.height;
                        // force the canvas to be the width of the container in the dom
                        canvas.style.width = "".concat(containerWidth, "px");
                        context = canvas.getContext('2d');
                        renderContext = {
                            canvasContext: context,
                            viewport: viewport
                        };
                        pageContainer = document.createElement('div');
                        pageContainer.setAttribute('id', "page-".concat(index + 1));
                        pageContainer.dataset.page = String(index + 1);
                        pageContainer.classList.add('pdf-content__page');
                        pageContainer.style.position = 'relative';
                        if (this.pdfContentWrapper) {
                            this.pdfContentWrapper.appendChild(pageContainer);
                        }
                        // render the page
                        return [4 /*yield*/, page.render(renderContext).promise];
                    case 1:
                        // render the page
                        _c.sent();
                        // add the text layer
                        pageContainer.append(this.addImageLayer(page, containerWidth, canvas));
                        _b = (_a = pageContainer).append;
                        return [4 /*yield*/, this.addTextLayer(page, containerWidth, canvas)];
                    case 2:
                        _b.apply(_a, [_c.sent()]);
                        // add image previews
                        this.addPreviewPanel(canvas, index + 1);
                        return [2 /*return*/];
                }
            });
        });
    };
    PdfRenderer.prototype.addImageLayer = function (page, containerWidth, canvas) {
        var image = new Image();
        image.src = canvas.toDataURL('image/jpeg');
        // when rendering the image layer, we want the renderer to know that we'll place the image on top
        // of a canvas that has been scaled up/down to fit into the containing div; so we need to calculate
        // the scale required to go from the original PDF width, to the container width.
        var viewport = page.getViewport({ scale: 1 });
        var textScale = containerWidth / viewport.width;
        // this viewport will have the correct scale to render image to, to be placed directly over the canvas
        viewport = page.getViewport({ scale: textScale });
        image.style.left = "".concat(canvas.offsetLeft, "px");
        image.style.top = "".concat(canvas.offsetTop, "px");
        image.style.height = "".concat(viewport.height, "px");
        image.style.width = "".concat(viewport.width, "px");
        return image;
    };
    PdfRenderer.prototype.addTextLayer = function (page, containerWidth, canvas) {
        return __awaiter(this, void 0, void 0, function () {
            var textLayer, viewport, textScale, _a, _b;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        textLayer = document.createElement('div');
                        textLayer.classList.add('textLayer');
                        viewport = page.getViewport({ scale: 1 });
                        textScale = containerWidth / viewport.width;
                        // this viewport will have the correct scale to render text to, to be placed directly over the canvas
                        viewport = page.getViewport({ scale: textScale });
                        textLayer.style.left = "".concat(canvas.offsetLeft, "px");
                        textLayer.style.top = "".concat(canvas.offsetTop, "px");
                        textLayer.style.height = "".concat(viewport.height, "px");
                        textLayer.style.width = "".concat(viewport.width, "px");
                        _b = (_a = this.pdfjsLib).renderTextLayer;
                        _c = {};
                        return [4 /*yield*/, page.getTextContent()];
                    case 1:
                        _b.apply(_a, [(_c.textContent = _d.sent(),
                                _c.container = textLayer,
                                _c.viewport = viewport,
                                _c.textDivs = [],
                                _c)]);
                        return [2 /*return*/, textLayer];
                }
            });
        });
    };
    PdfRenderer.prototype.addPreviewPanel = function (canvas, pageNum) {
        var _this = this;
        var panelPreview = document.createElement('button');
        panelPreview.dataset.page = String(pageNum);
        panelPreview.classList.add('preview-panel');
        panelPreview.addEventListener('click', function (e) { return _this.handlePreviewPanelClick(e); });
        var target = new Image();
        target.src = canvas.toDataURL('image/jpeg');
        var pageNumber = document.createElement('span');
        pageNumber.classList.add('preview-panel__page-number');
        pageNumber.innerText = String(pageNum);
        panelPreview.append(target, pageNumber);
        if (this.previewPanelsContainer) {
            this.previewPanelsContainer.appendChild(panelPreview);
        }
    };
    return PdfRenderer;
}());
export default PdfRenderer;
